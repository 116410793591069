import { booleanAttribute, ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faLock, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { ImageDirective } from '../../../../../../@common/DOM/image.directive';
import { ToDatePipe } from '../../../../../../@common/pipe/to-date.pipe';
import { TypeToStringPipe } from '../../../../../../@common/pipe/type-to-string.enum';
import { EventInfo, PublishedStatus } from '../../../../../../@generated';
import { AppRoute } from '../../../../../../routing/types/app-route';
import { BadgeComponent } from '../../../../global/badge/badge.component';

@Component({
    selector: 'app-event-item',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ RouterLink, ImageDirective, ToDatePipe, FaIconComponent, BadgeComponent, TypeToStringPipe ],
    styles: `
        @use "variables" as *;
        @use "functions" as *;

        a {
            text-decoration: none;
        }

        .private-badge {
            position: absolute;
            top: 10px;
            left: 10px;
            color: white;
            z-index: 10;
            padding: 0.35rem 0.55rem;
            border-radius: 1rem;
            background: #4b5563;
            font-size: 14px;
        }

        .card-item-body .info {
            display: flex;
            justify-content: space-between;
        }

        .event-list-view {
            display: flex;
            align-items: center;
            padding: 0.5rem;
            margin: 0.5rem 0;
            border-radius: 4px;
            cursor: pointer;

            .event-title {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                margin-bottom: 0.25rem;
            }

            img {
                aspect-ratio: 16/9;
                height: 70px;
                margin-right: 1rem;
                border-radius: 4px;
                object-fit: cover;
                transform: scale(1.05);

                @media screen and (max-width: 600px) {
                    height: 60px;
                    margin-right: 0.75rem;
                }

                @media screen and (max-width: 450px) {
                    height: 50px;
                    margin-right: 0.75rem;
                }
            }

            .right {
                display: flex;
                align-items: center;
                width: 100%;

                strong {
                    color: $dark;
                    width: 60%;
                    margin-right: 1.5rem;
                }

                div {
                    display: flex;
                    gap: 0.5rem;
                    width: 40%;

                    span {
                        width: 50%;
                        min-width: fit-content;
                    }
                }

                @media screen and (max-width: 767px) {
                    flex-direction: column;

                    strong {
                        width: 100%;
                        margin-right: 0;
                    }

                    div {
                        width: 100%;

                        span {
                            width: fit-content;
                            margin-right: 1rem;
                        }
                    }
                }
            }
        }
    `,
    template: `
        @if (!listView()) {
            <a class="card-item" routerLink="{{AppRoutes.Events}}/{{ event().slug }}">
                <div class="card-item-header">
                    @if (event().status === PublishedStatus.Private) {
                        <div class="private-badge">
                            <fa-icon [icon]="faLock"/>
                            Private
                        </div>
                    }

                    @if (event().status === PublishedStatus.Draft) {
                        <div class="private-badge">
                            <fa-icon [icon]="faPencilAlt"/>
                            Draft
                        </div>
                    }

                    <img [src]="event().thumbnail" [alt]="event().title + ' image'"
                         [attr.loading]="priorityImageLoading() ? 'eager' : 'lazy'"
                         [attr.fetchpriority]="priorityImageLoading() ? 'high' : 'low'"
                         [attr.decoding]="'async'"
                    />
                </div>
                <div class="card-item-body">
                    <h3>{{ event().title }}</h3>
                    <div class="info">
                        <p>{{ event().date | toDate }}</p>
                        <p>&nbsp;·&nbsp;</p>
                        <p>{{ event().total_videos ?? 0 }} videos</p>
                    </div>
                </div>
                <div class="card-item-footer">
                    @if (event().type) {
                        <app-badge [text]="event().type! | typeToString"/>
                    }
                </div>
            </a>
        } @else {
            <a class="event-list-view" routerLink="/{{AppRoutes.Events}}/{{ event().slug }}">
                <img [src]="event().thumbnail" alt="video thumbnail"
                     [attr.loading]="priorityImageLoading() ? 'eager' : 'lazy'"
                     [attr.fetchpriority]="priorityImageLoading() ? 'high' : 'low'"
                     [attr.decoding]="'async'"
                />
                <div class="right">
                    <strong class="event-title">{{ event().title }}</strong>
                    <div class="numbers">
                        <span class="light-color">{{ event().date | toDate }}</span>
                        <span class="light-color">{{ event().total_videos }} Videos</span>
                    </div>
                </div>
            </a>
        }
    `
})
export class EventItemComponent {
    public event = input.required<EventInfo>();
    public priorityImageLoading = input(false, { transform: booleanAttribute });
    public listView = input(false, { transform: booleanAttribute });
    public AppRoutes = AppRoute;
    protected readonly PublishedStatus = PublishedStatus;
    protected readonly faLock = faLock;
    protected readonly faPencilAlt = faPencilAlt;
}
