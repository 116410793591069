import { ChangeDetectionStrategy, Component, inject, Injector, input, OnInit, Signal } from '@angular/core';
import { Page } from '../../../../../../@common/entities/page.interface';
import { computedAsync } from '../../../../../../@common/rxjs/computed-async';
import { toNumber } from '../../../../../../@common/util/to-number';
import { AuthorService } from '../../../../../../@domain/author/application/author.service';
import { AuthorInfo } from '../../../../../../@generated';
import { AuthorCardComponent } from '../../../../global/author/author-card.component';
import { PaginationComponent } from '../../../../global/pagination/pagination.component';
import { SpinnerComponent } from '../../../../global/spinner/spinner.component';
import { CardGridComponent } from '../../../card-grid/card-grid.component';

@Component({
    selector: 'app-user-following',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [AuthorCardComponent, PaginationComponent, SpinnerComponent, CardGridComponent],
    styles: `
        :host {
            position: relative;
            flex: 1;
            display: flex;
            flex-direction: column;
        }
    `,
    template: `
        @if (authorPage(); as authorPage) {
            <div class="mt-2">
                @if (authorPage.results.length) {
                    <app-card-grid listView>
                        @for (author of authorPage.results; track author) {
                            <app-author-item [author]="author" [following]="true"/>
                        }
                    </app-card-grid>
                } @else {
                    <p class="mt-2 no-content">You do not follow any authors.</p>
                }
                <app-pagination [totalItems]="authorPage?.count" [pageSize]="pageSize"/>
            </div>
        } @else {
            <app-spinner/>
        }
    `
})
export class UserFollowingComponent implements OnInit {
    private authorService = inject(AuthorService);
    private injector = inject(Injector);

    public page = input(1, { transform: toNumber(1) });
    public authorPage!: Signal<Page<AuthorInfo> | undefined>;
    public pageSize = 8;

    public ngOnInit() {
        this.authorPage = computedAsync(
            () => this.authorService.getFollowedAuthors(this.page()!, this.pageSize),
            { injector: this.injector }
        );
    }
}
