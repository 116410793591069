import { ChangeDetectionStrategy, Component, effect, inject, signal } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { catchError, of, tap } from 'rxjs';
import { UserService } from 'src/app/@domain/user/application/user.service';
import { equalsValidator } from '../../../../../../@common/form/equals.validator';
import { strongPasswordValidator } from '../../../../../../@common/form/strong-password.validator';
import { NotificationService } from '../../../../../../@domain/notification/application/notification.service';
import { ErrorComponent } from '../../../../global/error/error.component';
import { SpinnerComponent } from '../../../../global/spinner/spinner.component';
import { ProfileHeaderComponent } from '../../../profile/profile-header/profile-header.component';

@Component({
    selector: 'app-user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: [ './user-edit.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ FormsModule, ErrorComponent, SpinnerComponent, ReactiveFormsModule, TooltipModule, ProfileHeaderComponent ]
})
export class UserEditComponent {
    private userService = inject(UserService);
    private notificationService = inject(NotificationService);
    private fb = inject(FormBuilder);

    user = this.userService.user;
    profileForm = this.fb.group({
        firstName: [ '', [ Validators.required ] ],
        lastName: [ '', [ Validators.required ] ],
        email: [ { value: '', disabled: true } ],
    });
    passwordForm = this.fb.group({
        oldPassword: [ '', [ Validators.required ] ],
        newPassword: [ '', [ Validators.required, strongPasswordValidator ] ],
        rePassword: [ '', [ Validators.required ] ],
    }, { validators: [ equalsValidator('newPassword', 'rePassword') ] });
    errorMessage = signal('');
    isLoadingProfile = signal(false);
    isLoadingPassword = signal(false);

    constructor() {
        effect(() => {
            const user = this.userService.user();
            this.profileForm.patchValue({
                firstName: user?.first_name,
                lastName: user?.last_name,
                email: user?.email,
            });
        });
    }

    updateProfile(): void {
        if (this.profileForm.invalid) {
            return;
        }

        this.isLoadingProfile.set(true);
        this.userService.updateProfile({
            first_name: this.firstName!.value!,
            last_name: this.lastName!.value!,
        }).pipe(
            tap(() => this.notificationService.success({detail:'Profile updated successfully!'})),
            catchError(() => of(null))
        ).subscribe(() => this.isLoadingProfile.set(false));
    }

    updatePassword() {
        if (this.passwordForm.invalid) {
            return;
        }

        this.isLoadingPassword.set(true);

        this.userService.updatePassword({
            old_password: this.oldPassword!.value!,
            new_password: this.rePassword!.value!,
        }).pipe(
            tap(() => this.notificationService.success({detail: 'Password updated successfully!'})),
            catchError(() => {
                this.errorMessage.set('Invalid password provided!');
                return of(null)
            })
        ).subscribe(() => {
            this.passwordForm.reset();
            this.isLoadingPassword.set(false)
        });
    }

    get firstName() {
        return this.profileForm.get('firstName');
    }

    get lastName() {
        return this.profileForm.get('lastName');
    }

    get oldPassword() {
        return this.passwordForm.get('oldPassword');
    }

    get newPassword() {
        return this.passwordForm.get('newPassword');
    }

    get rePassword() {
        return this.passwordForm.get('rePassword');
    }

}
