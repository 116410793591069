import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faCalendarDay, faCloudArrowUp, faIcons, faUsers, faUserTie, faVideo } from '@fortawesome/free-solid-svg-icons';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { AdminService } from '../../../@domain/admin/application/admin.service';
import { MetaService } from '../../../@domain/seo/meta.service';
import { AdminRoute } from '../../../routing/types/admin-route';
import { BreadcrumbsComponent } from '../../admin/breadcrumbs/breadcrumbs.component';
import { HeaderComponent } from '../header/header.component';

@Component({
    selector: 'app-admin-layout',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        HeaderComponent,
        RouterOutlet,
        RouterLink,
        RouterLinkActive,
        ConfirmDialog,
        BreadcrumbsComponent,
        FaIconComponent
    ],
    styles: `
        ::ng-deep {
            @import "../../../../styles/admin/admin";
        }

        :host {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100dvh;
        }

        main {
            padding-top: 4.75rem;
            min-height: 100dvh;
            display: flex;
            overflow: hidden;
        }

        .sidebar {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            width: 330px;
            background-color: rgb(255, 255, 255);
            padding: 1.5rem 1rem;
            box-shadow: rgba(145, 158, 171, 0.2) 0px 8px 10px -5px,
            rgba(145, 158, 171, 0.14) 0px 16px 24px 2px,
            rgba(145, 158, 171, 0.12) 0px;
            border-right: 1px solid rgba(145, 158, 171, 0.2);

            @media (max-width: 1200px) {
                width: 225px;
                padding: 1.5rem 0.5rem;
            }
        }

        .sidebar-link {
            display: flex;
            align-items: center;
            gap: 1rem;
            width: 100%;
            padding: 0.75rem 1rem;
            border-radius: 0.4rem;
            text-decoration: none;
            color: #64748b;
            font-weight: 500;
            transition: background-color 0.1s, color 0.1s;

            &:hover {
                background-color: rgba(145, 158, 171, 0.08);
                color: #333;
            }

            &.active {
                background-color: #020617;
                color: white;
            }
        }

        .content {
            flex: 1;
            padding: 1rem 2rem 5rem;
            overflow-y: auto;
        }

        .header {
            font-size: 1.2rem;
            font-weight: 600;
            margin-bottom: 1.5rem;
        }

        app-breadcrumbs {
            margin-bottom: 0.5rem;
        }
    `,
    template: `
        <app-header/>
        <main>
            <div class="sidebar">
                @for (item of sidebarItems; track item.title) {
                    <a [routerLink]="item.route" routerLinkActive="active" class="sidebar-link">
                        <fa-icon [icon]="item.icon"/>

                        {{ item.title }}
                    </a>
                }
            </div>
            <div class="content">
                <app-breadcrumbs [items]="breadcrumbs()"/>
                <router-outlet/>
            </div>
        </main>
        <p-confirmDialog/>
    `
})
export class AdminLayout {
    private adminService = inject(AdminService);
    private metaService = inject(MetaService);

    public breadcrumbs = this.adminService.getBreadcrumbs();
    public sidebarItems = [
        { title: 'Events', route: AdminRoute.Events, icon: faCalendarDay },
        { title: 'Videos', route: AdminRoute.Videos, icon: faVideo },
        { title: 'Authors', route: AdminRoute.Authors, icon: faUserTie },
        { title: 'Categories', route: AdminRoute.Categories, icon: faIcons },
        { title: 'Users', route: AdminRoute.Users, icon: faUsers },
        { title: 'Incoming', route: AdminRoute.Incoming, icon: faCloudArrowUp }
    ] as const;

    constructor() {
        this.metaService.set({
            title: 'Videolectures - Admin',
            description: undefined,
            image: undefined,
            index: false
        })
    }
}
