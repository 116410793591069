import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, EMPTY, throwError } from 'rxjs';
import { NotificationService } from 'src/app/@domain/notification/application/notification.service';
import { CookieService } from '../../../@domain/storage/application/cookie.service';
import { AppRoute } from '../../../routing/types/app-route';

export const AuthInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
    const cookieService = inject(CookieService);
    const notificationService = inject(NotificationService);
    const router = inject(Router);
    const token = cookieService.token();

    if (!req.withCredentials || !token) {
        return next(req).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse && error.status === 403) {
                    router.navigate([ `/${ AppRoute.Unauthorized }` ]);
                }

                return throwError(() => error);
            })
        );
    }

    const headers = req.headers.set('Authorization', `Token ${ token }`);
    return next(req.clone({ headers })).pipe(
        catchError(error => {
            if (error instanceof HttpErrorResponse && !req.url.includes('/auth') && error.status === 401) {
                notificationService.error({ detail: 'Your session has expired. Please log in again.' });
                cookieService.logout();
                return EMPTY;
            }

            if (error instanceof HttpErrorResponse && error.status === 403) {
                router.navigate([ `/${ AppRoute.Unauthorized }` ]);
            }

            return throwError(() => error);
        })
    );
}
