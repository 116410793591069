import { inject, Injectable } from '@angular/core';
import { catchError, EMPTY, firstValueFrom, map, Observable, of, shareReplay, startWith, Subject, switchMap, take, tap, throwError } from 'rxjs';
import { Page } from '../../../@common/entities/page.interface';
import { Logger } from '../../../@common/log/logger';
import { ManagePlaylistBody, Playlist, PlaylistInfo, V2PlaylistsResource, } from '../../../@generated';
import { NotificationService } from '../../notification/application/notification.service';

@Injectable({
    providedIn: 'root',
})
export class PlaylistService {
    private playlistResource = inject(V2PlaylistsResource);
    private notificationService = inject(NotificationService);
    private logger = inject(Logger);

    private refreshPlaylists$ = new Subject<void>();
    private allPlaylists$ = this.setupPlaylists();

    private setupPlaylists(): Observable<Page<PlaylistInfo>> {
        return this.refreshPlaylists$.pipe(
            startWith(null),
            switchMap(() => this.getPlaylists(1, 9999)),
            shareReplay(1)
        );
    }

    public getUsersPlaylists(): Observable<PlaylistInfo[]> {
        return this.allPlaylists$.pipe(
            map(page => page.results)
        );
    }

    public getPlaylists(page: number, pageSize: number): Observable<Page<PlaylistInfo>> {
        return this.playlistResource.getPlaylists(page, pageSize).pipe(
            shareReplay(1),
            catchError(e => {
                this.logger.apiError('Get playlists failed', e);
                return of({ count: 0, results: [] });
            })
        );
    }

    public getPlaylist(id: number): Observable<Playlist> {
        return this.refreshPlaylists$.pipe(
            startWith(null),
            switchMap(() => this.playlistResource.getPlaylistById(id)),
            shareReplay(1),
            catchError(e => {
                this.logger.apiError('Get playlist failed', e);
                return EMPTY;
            })
        );
    }

    public createPlaylist(body: ManagePlaylistBody): Observable<Playlist> {
        return this.playlistResource.createPlaylist(body).pipe(
            take(1),
            tap(() => this.refreshPlaylists$.next()),
            catchError(e => {
                this.logger.apiError('Failed to add playlist', e)
                return throwError(() => e);
            }),
        );
    }

    public updatePlaylist(id: number, body: ManagePlaylistBody) {
        return this.playlistResource.updatePlaylist(id, body)
            .pipe(
                take(1),
                tap(() => this.refreshPlaylists$.next()),
                catchError(e => {
                    this.logger.apiError('Failed to edit playlist', e)
                    return throwError(() => e);
                }),
            );
    }

    public deletePlaylist(id: number): Observable<Playlist> {
        return this.playlistResource.deletePlaylist(id).pipe(
            take(1),
            tap(() => this.refreshPlaylists$.next()),
            catchError(e => {
                this.logger.apiError('Failed to delete playlist', e)
                return throwError(() => e);
            }),
        );
    }

    public async addVideoToPlaylist(playlistId: number, videoId: number) {
        try {
            await firstValueFrom(this.playlistResource.addVideoToPlaylist(playlistId, videoId));
            this.refreshPlaylists$.next();
            this.notificationService.success({ detail: 'Video  added to playlist' });
        } catch (e) {
            this.notificationService.error({ detail: 'Failed to add video to playlist' });
            this.logger.apiError('Failed to add video to playlist', e);
            throw e;
        }
    }

    public async removeVideoFromPlaylist(playlistId: number, videoId: number) {
        try {
            await firstValueFrom(this.playlistResource.removeVideoFromPlaylist(playlistId, videoId));
            this.refreshPlaylists$.next();
        } catch (e) {
            this.logger.apiError('Failed to remove video from playlist', e);
            throw e;
        }
    }

}
