import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { VideoInfo } from '../../../../../../@generated';
import { CardGridComponent } from '../../../card-grid/card-grid.component';
import { VideoItemComponent } from '../../video-item/video-item.component';

@Component({
    selector: 'app-history',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ VideoItemComponent, CardGridComponent ],
    styles: `
        @use "variables" as *;

        :host {
            display: block;
            width: 100%;
            min-height: 300px;
        }

        app-card-grid {
            padding-bottom: 1rem;
        }
    `,
    template: `
        <app-card-grid [listView]="false">
            @for (video of history(); track video.id) {
                <app-video-item [video]="video" [part]="video.parts[0]" priorityImageLoading/>
            }
        </app-card-grid>
    `
})
export class HistoryComponent {
    public history = input.required<VideoInfo[]>();
}
