import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ImageDirective } from '../../../../@common/DOM/image.directive';
import { AuthorInfo } from '../../../../@generated';
import { AppRoute } from '../../../../routing/types/app-route';

@Component({
    selector: 'app-author-item',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLink, ImageDirective],
    styles: `
      @use "variables" as *;

      .author {
        cursor: pointer;
        display: flex;
        padding: 1rem 0.25rem;
        border-radius: 0.25rem;
        text-decoration: none;

        &:hover {
          background-color: rgba($bg-light, 0.5);
        }

        .name {
          color: $dark;
        }

        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 1rem;
        }

        div {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr;
          align-items: center;

          .numbers {
            display: flex;
            color: $grey;
            font-size: 16px;

            p {
              width: 50%;
            }
          }
        }

        @media screen and (max-width: 550px) {
          div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .numbers {
              display: flex;
              flex-direction: row;
              width: 100%;
              margin-top: 0.25rem;

              p {
                width: fit-content;
                margin-right: 1rem;
              }
            }
          }
        }
      }
    `,
    template: `
        <a class="author" routerLink="/{{ AppRoutes.Authors }}/{{ author.slug}}">
            <img src="/assets/character.png" alt="author_img" loading="lazy"/>
            <div>
                <p class="name">{{ author.name }}</p>
                <div class="numbers">
                    <p class="videos">{{ author.total_videos }} Videos</p>
                    <p class="views">{{ author.total_views }} Views</p>
                </div>
            </div>
        </a>
    `
})
export class AuthorCardComponent {
    @Input({ required: true }) author!: AuthorInfo;
    @Input() following = false;
    AppRoutes = AppRoute;
}
