import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ModalService } from '../../../../../@domain/modal/modal.service';

@Component({
    selector: 'app-modal-header',
    templateUrl: './modal-header.component.html',
    styleUrls: ['./modal-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [FaIconComponent]
})
export class ModalHeaderComponent {
    // TODO: remove this
    private modalService = inject(ModalService);

    @Input() modalTitle: string = '';
    @Output() onClose = new EventEmitter<void>();
    faTimes = faTimes;

    onCloseModal(): void {
        this.onClose.emit();
        this.modalService.close();
    }
}
