import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Injector, input, OnInit, signal, Signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { filter, Observable, switchMap } from 'rxjs';
import { BookmarkService } from 'src/app/@domain/bookmark/application/bookmark.service';
import { Page } from '../../../../../../@common/entities/page.interface';
import { computedAsync } from '../../../../../../@common/rxjs/computed-async';
import { toNumber } from '../../../../../../@common/util/to-number';
import { SortOrder, VideoInfo } from '../../../../../../@generated';
import { PaginationComponent } from '../../../../global/pagination/pagination.component';
import { SpinnerComponent } from '../../../../global/spinner/spinner.component';
import { CardGridComponent } from '../../../card-grid/card-grid.component';
import { VideoItemComponent } from '../../video-item/video-item.component';

@Component({
    selector: 'app-user-bookmarks',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ VideoItemComponent, PaginationComponent, SpinnerComponent, CardGridComponent, AsyncPipe ],
    styles: `
        :host {
            position: relative;
            flex: 1;
            display: flex;
            flex-direction: column;
        }
    `,
    template: `
        @if (bookmarksPage$ | async; as bookmarkPage) {
            <app-card-grid listView>
                @for (video of bookmarkPage.results; track video; let even = $even) {
                    <app-video-item [video]="video" [part]="video.parts[0]" isBookmark listView />
                }
            </app-card-grid>

            @if (bookmarkPage.results.length) {
                <app-pagination [totalItems]="bookmarkPage.count" [pageSize]="pageSize"/>
            } @else {
                <p class="no-content">You do not have any saved bookmarks.</p>
            }
        } @else {
            <app-spinner/>
        }
    `
})
export class UserBookmarksComponent {
    private bookmarksService = inject(BookmarkService);

    public page = input(1, { transform: toNumber(1) });
    public bookmarksPage$!: Observable<Page<VideoInfo> | undefined>;
    public pageSize = 8;

    constructor() {
        this.bookmarksPage$ = toObservable(this.page).pipe(
            filter(page => !!page),
            switchMap(page => this.bookmarksService.getBookmarkedVideos(page, this.pageSize, SortOrder.Descending))
        );
    }
}
