import { Routes } from '@angular/router';
import { LandingPage } from '../@page/app/landing/landing.page';
import ProfilePage from '../@page/app/profile/profile.page';
import { UserEditComponent } from '../@ui/app/content/forms/search/edit/user-edit.component';
import { UserBookmarksComponent } from '../@ui/app/content/sections/user-profile-table/user-bookmarks/user-bookmarks.component';
import { UserFollowingComponent } from '../@ui/app/content/sections/user-profile-table/user-following/user-following.component';
import { UserPlaylistComponent } from '../@ui/app/content/sections/user-profile-table/user-playlist/user-playlist.component';
import { authGuardFn } from './guards/auth.guard';
import { AppRoute } from './types/app-route';

export const APP_ROUTES: Routes = [
    {
        path: AppRoute.Home,
        pathMatch: 'full',
        component: LandingPage
    },
    {
        path: AppRoute.Register,
        loadComponent: () => import('../@page/app/register/register.page')
    },
    {
        path: AppRoute.Login,
        loadComponent: () => import('../@page/app/login/login.page')
    },
    {
        path: AppRoute.ForgotPassword,
        loadComponent: () => import('../@page/app/forgot-password/forgot-password.page')
    },
    {
        path: AppRoute.Profile,
        canActivate: [ authGuardFn ],
        loadChildren: () => [
            {
                path: 'edit',
                component: UserEditComponent
            },
            {
                path: '',
                component: ProfilePage,
                children: [
                    // { path: 'videos', component: UserUploadedVideosComponent },
                    { path: 'playlists', component: UserPlaylistComponent },
                    { path: 'following', component: UserFollowingComponent },
                    { path: 'bookmarks', component: UserBookmarksComponent },

                    { path: '**', redirectTo: 'playlists', pathMatch: 'full' }
                ]
            },
            {
                path: '**',
                redirectTo: '',
                pathMatch: 'full'
            }
        ]
    },
    {
        path: AppRoute.Events,
        loadComponent: () => import('../@page/app/events/events.page'),
    },
    {
        path: `${ AppRoute.Events }/:slug`,
        loadComponent: () => import('../@page/app/event/event.page'),
    },
    {
        path: AppRoute.Videos,
        loadComponent: () => import('../@page/app/videos/videos.page'),
    },
    {
        path: AppRoute.DeprecatedLectures,
        loadComponent: () => import('../@page/app/videos/videos.page'),
    },
    {
        path: `${ AppRoute.Videos }/${ AppRoute.Slug }`,
        loadComponent: () => import('../@page/app/video/video.page'),
    },
    {
        path: `${ AppRoute.DeprecatedLectures }/${ AppRoute.Slug }`,
        loadComponent: () => import('../@page/app/video/video.page'),
    },
    {
        path: AppRoute.News,
        loadComponent: () => import('../@page/app/news/news.page'),
    },
    {
        path: AppRoute.Categories,
        loadComponent: () => import('../@page/app/categories/categories.page'),
    },
    {
        path: `${ AppRoute.Categories }/:id`,
        loadComponent: () => import('../@page/app/category/category.page'),
    },
    {
        path: AppRoute.History,
        loadComponent: () => import('../@page/app/history/history.page'),
        canActivate: [ authGuardFn ],
    },
    {
        path: `${ AppRoute.Playlists }/:id`,
        loadComponent: () => import('../@page/app/playlist/playlist.page'),
        canActivate: [ authGuardFn ],
    },
    {
        path: `${ AppRoute.Authors }/:slug`,
        loadComponent: () => import('../@page/app/author/author.page'),
    },
    {
        path: `${ AppRoute.LecturerDeprecated }/:id`,
        loadComponent: () => import('../@page/app/author/author.page'),
    },
    {
        path: AppRoute.LectureAgreement,
        loadComponent: () => import('../@page/app/author-agreement/author-agreement.page'),
    },
    {
        path: 'activate/:id/:token',
        loadComponent: () => import('../@page/app/activate-account/activate-account.page'),
    },
    {
        path: 'reset-password/:id/:token',
        loadComponent: () => import('../@page/app/password-change/password-change.page'),
    },
    {
        path: AppRoute.Search,
        loadComponent: () => import('../@page/app/search/search.page')
    },
    {
        path: AppRoute.Company,
        loadComponent: () => import('../@page/app/company/company.page')
    },
    {
        path: AppRoute.PrivacyTermsOfUse,
        loadComponent: () => import('../@page/app/privacy-terms-of-use/privacy-terms.page')
    },
    {
        path: AppRoute.NotFound,
        loadComponent: () => import('../@page/app/not-found/not-found.page')
    },
    {
        path: AppRoute.Unauthorized,
        loadComponent: () => import('../@page/app/unauthorized/unauthorized.page')
    },
    {
        path: AppRoute.Error,
        loadComponent: () => import('../@page/app/error/error.page')
    },
];
