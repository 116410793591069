import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { VideoService } from 'src/app/@domain/video/application/video.service';
import { SpinnerComponent } from '../../../../global/spinner/spinner.component';
import { CardGridComponent } from '../../../card-grid/card-grid.component';
import { VideoItemComponent } from '../../video-item/video-item.component';

@Component({
    selector: 'app-trending-videos',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [VideoItemComponent, SpinnerComponent, AsyncPipe, CardGridComponent],
    styles: `
        :host {
            display: block;
            width: 100%;
            min-height: 300px;
        }
    `,
    template: `
        @if (trendingVideos$ | async; as videos) {
            <app-card-grid [listView]="false">
                @for (video of videos; track video) {
                    <app-video-item [video]="video" [part]="video.parts[0]"/>
                }
            </app-card-grid>
        } @else {
            <app-spinner/>
        }
    `
})
export class TrendingVideosComponent {
    private videosService = inject(VideoService);

    public trendingVideos$ = this.videosService.getMostViewedVideos()
}
