import { Route } from '@angular/router';
import { AdminRoute } from './types/admin-route';

export const ADMIN_ROUTES: Route[] = [
    {
        path: AdminRoute.Landing,
        pathMatch: 'full',
        loadComponent: () => import('../@page/admin/landing/landing.page')
    },
    {
        path: AdminRoute.Videos,
        loadChildren: () => [
            {
                path: '',
                pathMatch: 'full',
                loadComponent: () => import('../@page/admin/video/list/video-list.page'),
            },
            {
                path: 'new',
                loadComponent: () => import('../@page/admin/video/manage/video-manage.page'),
            },
            {
                path: ':id',
                loadComponent: () => import('../@page/admin/video/manage/video-manage.page'),
            },
            {
                path: ':id/sync/:part/:language',
                loadComponent: () => import('../@page/admin/video/synchronization/video-synchronization.page'),
            }
        ]
    },
    {
        path: AdminRoute.Events,
        loadChildren: () => [
            {
                path: '',
                pathMatch: 'full',
                loadComponent: () => import('../@page/admin/event/list/event-list.page'),
            },
            {
                path: 'new',
                loadComponent: () => import('../@page/admin/event/manage/event-manage.page'),
            },
            {
                path: ':id',
                loadComponent: () => import('../@page/admin/event/manage/event-manage.page'),
            }
        ]
    },
    {
        path: AdminRoute.Authors,
        loadChildren: () => [
            {
                path: '',
                pathMatch: 'full',
                loadComponent: () => import('../@page/admin/author/list/author-list.page'),
            },
            {
                path: 'new',
                loadComponent: () => import('../@page/admin/author/manage/author-manage.page'),
            },
            {
                path: ':id',
                loadComponent: () => import('../@page/admin/author/manage/author-manage.page'),
            }
        ]
    },
    {
        path: AdminRoute.Categories,
        loadChildren: () => [
            {
                path: '',
                pathMatch: 'full',
                loadComponent: () => import('../@page/admin/category/list/category-list.page'),
            },
            {
                path: 'new',
                loadComponent: () => import('../@page/admin/category/manage/category-manage.page'),
            },
            {
                path: ':id',
                loadComponent: () => import('../@page/admin/category/manage/category-manage.page'),
            }
        ]
    },
    {
        path: AdminRoute.Users,
        data: {
            breadcrumb: 'Users'
        },
        loadChildren: () => [
            {
                path: '',
                pathMatch: 'full',
                loadComponent: () => import('../@page/admin/user/list/user-list.page'),
            },
            {
                path: 'new',
                loadComponent: () => import('../@page/admin/user/manage/user-manage.page'),
            },
            {
                path: ':id',
                loadComponent: () => import('../@page/admin/user/manage/user-manage.page'),
            }
        ]
    },
    {
        path: AdminRoute.Incoming,
        loadChildren: () => [
            {
                path: '',
                pathMatch: 'full',
                loadComponent: () => import('../@page/admin/incoming/list/incoming.page'),
            }
        ]
    },
    {
        path: '**',
        redirectTo: AdminRoute.Landing
    }
];
