import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faFacebook, faLinkedin, faSquareXTwitter } from '@fortawesome/free-brands-svg-icons';
import { ImageDirective } from '../../../@common/DOM/image.directive';
import { AppRoute } from '../../../routing/types/app-route';

@Component({
    selector: 'app-footer',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLink, FaIconComponent, ImageDirective],
    styles: `
        @use "variables" as *;

        $footer-color: #9ca3af;

        :host {
            background-color: $dark;
        }

        footer {
            display: flex;
            color: #d1d5db;
            font-size: 18px;
            padding: 2.5rem 2rem 3.5rem;
            gap: 8rem;
        }

        a {
            color: #d1d5db;
            text-decoration: none;
            transition: color 0.1s;

            &:hover {
                color: $footer-color;
            }
        }

        .group {
            display: flex;
            flex-direction: column;
            gap: 0.75rem;

            &:last-child {
                margin-left: auto;
            }
        }

        h3 {
            color: $footer-color;
            padding: 1rem 0 0.5rem;
            font-size: 18px;
            font-weight: 500;
        }

        .socials {
            display: flex;
            gap: 1.5rem;
            align-items: center;
        }

        .facebook {
            font-size: 28px;
        }

        .twitter {
            font-size: 30px;
        }

        .linkedin {
            font-size: 30px;
        }
    `,
    template: `
        <footer class="container">
            <div class="group">
                <h3>VIDEOLECTURES</h3>
                <a routerLink="{{AppRoutes.Company}}">About</a>
                <a routerLink="{{AppRoutes.Company}}" fragment="team">Team</a>
                <a routerLink="{{AppRoutes.Company}}" fragment="contact">Contact</a>
                <a routerLink="{{AppRoutes.Company}}" fragment="office">Office</a>
            </div>

            <div class="group">
                <h3>LEGAL</h3>
                <a routerLink="/{{AppRoutes.LectureAgreement}}">Disclosure Statement</a>
                <a routerLink="/{{AppRoutes.PrivacyTermsOfUse}}">Privacy & Terms of Use</a>
            </div>

            <div class="group">
                <h3>CREATED BY</h3>
                <p>
                    Jožef Stefan Institute
                </p>
                <p>
                    Centre for Knowledge Transfer and Information Technologies
                </p>
                <div class="socials">
                    <a href="https://ijs.si" target="_blank">
                        <img src="/assets/logos/ijs.svg" alt="ijs logo" height="75"/>
                    </a>
                    <a href="https://www.facebook.com/videolectures/" target="_blank">
                        <fa-icon [icon]="faFacebook" class="facebook"/>
                    </a>
                    <a href="https://x.com/videolectures" target="_blank">
                        <fa-icon [icon]="faSquareXTwitter" class="twitter"/>
                    </a>
                    <a href="https://www.linkedin.com/company/videolectures-net/" target="_blank">
                        <fa-icon [icon]="faLinkedin" class="linkedin"/>
                    </a>
                </div>
            </div>
        </footer>
    `
})
export class FooterComponent {
    public AppRoutes = AppRoute;
    public faLinkedin = faLinkedin;
    public faSquareXTwitter = faSquareXTwitter;
    public faFacebook = faFacebook;
}
