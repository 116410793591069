import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import { NewsService } from 'src/app/@domain/news/application/news.service';
import { NewsInfo, NewsInfoSort, SortOrder } from '../../../../../../@generated';
import { SpinnerComponent } from '../../../../global/spinner/spinner.component';
import { CardGridComponent } from '../../../card-grid/card-grid.component';
import { NewsItemComponent } from '../../news/news-item.component';

@Component({
    selector: 'app-news',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NewsItemComponent, SpinnerComponent, AsyncPipe, CardGridComponent],
    styles: `
        :host {
            display: block;
            width: 100%;
        }
    `,
    template: `
        @if (news$ | async; as news) {
            @if (news.length) {
                <app-card-grid listView>
                    @for (newsItem of news; track newsItem.id) {
                        <app-news-item [newsItem]="newsItem"/>
                    }
                </app-card-grid>
            } @else {
                <p class="no-content mb-4">There are no news at the moment.</p>
            }
        } @else {
            <app-spinner class="mt-2"/>
        }
    `
})
export class NewsComponent {
    public news$?: Observable<NewsInfo[]>;

    constructor(private newsService: NewsService) {
        this.news$ = this.newsService.getAllNews(1, 4, SortOrder.Descending, NewsInfoSort.Created)
            .pipe(
                map(news => news.results ?? [])
            )
    }
}
