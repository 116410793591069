import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ImageDirective } from '../../../../@common/DOM/image.directive';
import { Modal } from '../../../../@domain/modal/modal.enum';
import { ModalService } from '../../../../@domain/modal/modal.service';
import { AppRoute } from '../../../../routing/types/app-route';
import { ModalBodyComponent } from '../../global/modal/modal-body/modal-body.component';
import { ModalComponent } from '../../global/modal/modal.component';

@Component({
    selector: 'app-welcome-modal',
    styles: `
      @use 'functions' as *;

      app-modal-body {
        z-index: 1000;
        height: 70dvh;
      }

      .body {
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
      }

      .message {
        flex: 1;
        overflow-y: auto;

        @include break(660px) {
          font-size: 0.9rem;
        }
      }

      .close {
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        padding-top: 0.5rem;
      }

      .logo {
        width: fit-content;
        margin-left: auto;
      }
    `,
    imports: [
        FaIconComponent,
        ImageDirective,
        ModalBodyComponent,
        ModalComponent,
        RouterLink
    ],
    template: `
        <app-modal [isSmall]="true" [tag]="Modal.WelcomeNotice">
            <app-modal-body class="text-center">
                <div class="body px-1">
                    <div class="close">
                        <fa-icon [icon]="faTimes" size="lg" (click)="onCloseModal()"></fa-icon>
                    </div>
                    <h2 class="text-center mb-1">
                        DISCLAIMER:
                    </h2>
                    <div class="message">
                        <p class="text-center">
                            "We’re back, but we’re still in the process of developing our new website. We’d love to hear
                            your
                            suggestions
                            for changes, or if some of your favorite talks are not available!
                        </p>
                        <br>
                        <p class="text-center">
                            We’ll be making daily improvements over the next couple of weeks, so stay tuned for an even
                            better
                            browsing
                            experience. 🚀
                        </p>
                        <br>
                        <p class="text-center">
                            Thank you for being a part of our journey. We can’t wait to make your visits here more
                            enjoyable
                            than
                            ever!
                        </p>
                        <br>
                        <p class="text-center">
                            Contact us at <a href="mailto:info@videolectures.net">info&#64;videolectures.net</a>
                        </p>
                    </div>
                    <button class="btn btn-primary mt-3 mb-1" (click)="onCloseModal()">Continue</button>

                    <div class="mt-1 text-center">
                        <a [routerLink]="AppRoutes.Home">
                            <picture>
                                <source
                                    srcset="../../../assets/vln_default.svg"
                                    media="(min-width: 851px)"
                                />
                                <img src="../../../assets/vln_tiny.svg" alt="logo" style="height: 2rem" loading="lazy"/>
                            </picture>
                        </a>
                    </div>
                </div>
            </app-modal-body>
        </app-modal>
    `
})
export class WelcomeModalComponent {
    private modalService = inject(ModalService);

    protected readonly faTimes = faTimes;
    protected readonly Modal = Modal;
    protected readonly AppRoutes = AppRoute;

    onCloseModal(): void {
        this.modalService.close();
        localStorage.setItem('seenModal', 'true');
    }
}
