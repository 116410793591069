import { SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { EventService } from '../../../../../../@domain/event/application/event.service';
import { AppRoute } from '../../../../../../routing/types/app-route';
import { CardGridComponent } from '../../../card-grid/card-grid.component';
import { EventItemComponent } from '../../events/event-item/event-item.component';

@Component({
    selector: 'app-upcoming-events-section',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ RouterLink, FaIconComponent, SlicePipe, CardGridComponent, EventItemComponent ],
    styles: `
        @use "variables" as *;
        @use "functions" as *;

        h1 {
            font-size: 32px !important;
            color: $black !important;
        }

        .event {
            background-color: $bg-light;
            display: flex;
            padding: 1rem;
            margin-bottom: 1rem;

            .date {
                padding: 1rem 1.5rem;
                text-align: center;
                margin-right: 1rem;
                background-color: $white;

                h3 {
                    font-size: 26px;
                    font-weight: 700;
                    color: $dark;
                }

                p {
                    color: $grey;
                    font-size: 16px;
                }
            }

            .text {
                h5 {
                    padding-bottom: 1rem;
                    font-size: 16px;
                    font-weight: 600;
                    color: $dark;
                }

                p {
                    color: $grey;
                    font-size: 16px;
                }
            }
        }
    `,
    template: `
        <section class="all-events">
            <app-card-grid listView>
                @for (event of upcomingEvents() | slice: 0:2; track event) {
                    <app-event-item [event]="event" listView/>
                }
            </app-card-grid>

            @if (upcomingEvents()?.length === 0) {
                <p class="no-content mb-4">There are no upcoming events at the moment.</p>
            }
        </section>
    `
})
export class UpcomingEventsSectionComponent {
    private eventService = inject(EventService);

    public upcomingEvents = toSignal(this.eventService.getUpcomingEvents());
}
