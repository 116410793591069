import { ChangeDetectionStrategy, Component, inject, Injector, input, OnInit, Signal } from '@angular/core';
import { Page } from 'src/app/@common/entities/page.interface';
import { ItemsViewService } from 'src/app/@domain/items-view/items-view.service';
import { PlaylistService } from 'src/app/@domain/playlist/application/playlist.service';
import { computedAsync } from '../../../../../../@common/rxjs/computed-async';
import { toNumber } from '../../../../../../@common/util/to-number';
import { Modal } from '../../../../../../@domain/modal/modal.enum';
import { ModalService } from '../../../../../../@domain/modal/modal.service';
import { PlaylistInfo } from '../../../../../../@generated';
import { PaginationComponent } from '../../../../global/pagination/pagination.component';
import { SpinnerComponent } from '../../../../global/spinner/spinner.component';
import { CreatePlaylistModalComponent } from '../../../../modals/create-playlist-modal/create-playlist-modal.component';
import { CardGridComponent } from '../../../card-grid/card-grid.component';
import { PlaylistItemComponent } from '../../playlist-item/playlist-item.component';
import { AddCardComponent } from '../add-card/add-card.component';

@Component({
    selector: 'app-user-playlist',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ PlaylistItemComponent, PaginationComponent, SpinnerComponent, CreatePlaylistModalComponent, CardGridComponent, AddCardComponent ],
    styles: `
        :host {
            position: relative;
            flex: 1;
            display: flex;
            flex-direction: column;
        }
    `,
    template: `
        @if (playlistPage(); as page) {
            <app-card-grid [listView]="!isGridView()">
                <app-add-card text="Add new playlist" (click)="openCreatePlaylistModal()"/>

                @for (playlist of page.results; track playlist) {
                    <app-playlist-item [playlist]="playlist" [listView]="!isGridView()"/>
                }
            </app-card-grid>

            @if (page.results.length) {
                <app-pagination [totalItems]="playlistPage()!.count" [pageSize]="pageSize"/>
            }
        }


        @if (!playlistPage()) {
            <app-spinner/>
        }

        <app-create-playlist-modal/>
    `
})
export class UserPlaylistComponent implements OnInit {
    private playlistService = inject(PlaylistService);
    private itemsViewService = inject(ItemsViewService);
    private modalService = inject(ModalService);
    private injector = inject(Injector);

    public page = input(1, { transform: toNumber(1) });
    public isGridView = this.itemsViewService.isGridView;
    public playlistPage!: Signal<Page<PlaylistInfo> | undefined>;
    public pageSize = 8;

    public ngOnInit() {
        this.playlistPage = computedAsync(
            () => this.playlistService.getPlaylists(this.page(), this.pageSize),
            { injector: this.injector }
        );
    }

    openCreatePlaylistModal(): void {
        this.modalService.open(Modal.CreatePlaylist);
    }

}
