import { isPlatformServer } from '@angular/common';
import { inject, PLATFORM_ID, REQUEST, RESPONSE_INIT } from '@angular/core';
import { CanActivateFn, RedirectCommand, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { SlugService } from '../../@domain/slug/application/slug.service';
import { EntityType } from '../../@generated';
import { AppRoute } from '../types/app-route';

export const slugGuardFn: CanActivateFn = async (route) => {
    const slugService = inject(SlugService);
    const router = inject(Router);
    const platformId = inject(PLATFORM_ID);

    const slug: string = route.params.slug;
    const fileExtensions = [ '.css', '.js' ];

    if (!!slug && fileExtensions.some(ext => slug.includes(ext))) {
        console.error('received slug', route.params.slug, typeof route.params.slug);
        return false;
    }

    const slugType = await firstValueFrom(slugService.getSlugType(slug));
    const redirectPath = getRedirectPath(slugType, slug);

    console.log(`redirecting slug: ${ slug } to`, redirectPath);
    if (isPlatformServer(platformId)) {
        return new RedirectCommand(router.createUrlTree([ redirectPath ]));
    } else {
        return router.createUrlTree([ redirectPath ]);
    }
};

function getRedirectPath(slugType: string | undefined, slug: string) {
    if (slugType === EntityType.Event) {
        return '/' + AppRoute.Events + '/' + slug
    }

    if (slugType === EntityType.Video) {
        return '/' + AppRoute.Videos + '/' + slug
    }

    if (slugType === EntityType.Author) {
        return '/' + AppRoute.Authors + '/' + slug
    }

    return '/' + AppRoute.NotFound
}
