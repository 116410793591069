import { inject } from '@angular/core';
import { CanActivateFn, Router, } from '@angular/router';
import { UserService } from '../../@domain/user/application/user.service';
import { AppRoute } from '../types/app-route';

export const authGuardFn: CanActivateFn = (_, state) => {
    const router = inject(Router);
    const userService = inject(UserService);

    if (userService.isAuthenticated()) {
        return true;
    }

    return router.createUrlTree([ '/' + AppRoute.Login ], { queryParams: { next: state.url } });
}
